import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { DrawerBar } from "../AppBar/DrawerBar";
import { TopAppBar } from "../AppBar/TopAppBar";
import { AddUser } from "./AddUser";
import { ChangeUser } from "./ChangeUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    root: {
      display: "flex",
    },
  })
);

export const AdminHomePage = () => {
  const classes = useStyles();
  const data = ["AddUser", "ChangeUser", "Logout"];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopAppBar />
      <BrowserRouter>
        <DrawerBar options={data} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/admin-home-page" component={AddUser} />
            <Route path="/AddUser" component={AddUser} />
            <Route path="/ChangeUser" component={ChangeUser} />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
};
