import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TopBarComponent } from "./TopBarComponent";
import project from "../../assets/projects/project_1.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "50rem",
    },
    textTitle2: {
      width: "100%",
      fontSize: 22,
      lineHeight: "1.7em",
      fontWeight: 500,
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle3: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: 300,
      fontStyle: "italic",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle4: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: "normal",
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
    container_project: {
      display: "flex",
      flexDirection: "row",
    },
    img: {
      width: 200,
    },
  })
);

type ProjectComponentProps = {
  name: string;
  image_path: string;
  details: string;
};

const ProjectComponent = ({
  name,
  image_path,
  details,
}: ProjectComponentProps) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.textTitle2}>
        {name}
      </Typography>
      <div className={classes.container_project}>
        <Typography variant="h3" className={classes.textTitle4}>
          {details}
        </Typography>
        <img className={classes.img} alt="project_1" src={image_path} />
      </div>
    </div>
  );
};

export const Projects = () => {
  const classes = useStyles();

  const project1 = "ARuler - App Store - Website - YouTube";
  const project_detail_1 =
    "ARuler was one of the first ARKit apps released on iOS 11. Its early prototype developed by myself in a couple of hours was featured by The Verge, Mashable, Daring Fireball and MacRumors. ARuler was developed by Alessio Buratti and I who worked together during summer 2017 to bring an accurate augmented reality ruler to iOS. It uses ARKit to detect planar surfaces and cloud points to accurately describe the real environment. The user can then place points in the virtual environment and the app can compute distances, areas or volumes between the chosen points. It is completely interactive to improve the user experience of placing, moving and deleting points in the virtual environment.";

  const project_image_1 = project;

  return (
    <div className={classes.container}>
      <TopBarComponent title="Projects" />
      <ProjectComponent
        name={project1}
        image_path={project_image_1}
        details={project_detail_1}
      />
      <ProjectComponent
        name={project1}
        image_path={project_image_1}
        details={project_detail_1}
      />
      <ProjectComponent
        name={project1}
        image_path={project_image_1}
        details={project_detail_1}
      />
      <ProjectComponent
        name={project1}
        image_path={project_image_1}
        details={project_detail_1}
      />
    </div>
  );
};
