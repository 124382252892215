import React from "react";
// import { List, Typography, ListItem, Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TopBarComponent } from "./TopBarComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "50rem",
    },
    textTitle2: {
      width: "100%",
      fontSize: 22,
      lineHeight: "1.7em",
      fontWeight: 500,
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle3: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: 300,
      fontStyle: "italic",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle4: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: "normal",
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
  })
);

export const Education = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TopBarComponent title="Education" />
    </div>
  );
};
