import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, CssBaseline, Toolbar, Typography } from "@material-ui/core";
import { drawerWidth } from "./DrawerBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  })
);

export const TopAppBar = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Portfolio
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};
