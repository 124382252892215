import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,

    details: {
      display: "flex",
    },
    avatar: {
      height: 110,
      width: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
    locationText: {
      paddingLeft: "15px",
    },
    buttonProperty: {
      position: "absolute",
      top: "50%",
    },
    uiProgess: {
      position: "fixed",
      zIndex: 1000,
      height: "31px",
      width: "31px",
      left: "50%",
      top: "35%",
    },
    progess: {
      position: "absolute",
    },
    uploadButton: {
      marginLeft: "8px",
      margin: theme.spacing(1),
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10,
    },
    submitButton: {
      marginTop: "10px",
    },
    root: {
      display: "flex",
    },
  })
);

export const AddUser = () => {
  const classes = useStyles();

  return (
    <>
      <Card>
        <CardContent>
          <div className={classes.details}>
            <div>
              <Typography
                className={classes.locationText}
                gutterBottom
                variant="h4"
              >
                Umesh Verma
                {/* {this.state.firstName} {this.state.lastName} */}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                startIcon={<CloudUploadIcon />}
                className={classes.uploadButton}
                // onClick={this.profilePictureHandler}
              >
                Upload Photo
              </Button>
              <input type="file" />

              {/* {this.state.imageError ? (
        <div className={classes.customError}>
          {" "}
          Wrong Image Format || Supported Format are PNG and JPG
        </div>
      ) : (
        false
      )} */}
            </div>
          </div>
          {/* <div className={classes.progress} /> */}
        </CardContent>
        <Divider />
      </Card>

      <br />
      <Card>
        <form autoComplete="off" noValidate>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  margin="dense"
                  name="firstName"
                  variant="outlined"
                  //   value={this.state.firstName}
                  //   onChange={this.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  margin="dense"
                  name="lastName"
                  variant="outlined"
                  //   value={this.state.lastName}
                  //   onChange={this.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  variant="outlined"
                  // disabled={true}
                  //   value={this.state.email}
                  //   onChange={this.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  margin="dense"
                  name="phone"
                  type="number"
                  variant="outlined"
                  // disabled={true}
                  //   value={this.state.phoneNumber}
                  //   onChange={this.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="User Name"
                  margin="dense"
                  name="userHandle"
                  // disabled={true}
                  variant="outlined"
                  //   value={this.state.username}
                  //   onChange={this.handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  margin="dense"
                  name="country"
                  variant="outlined"
                  //   value={this.state.country}
                  //   onChange={this.handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions />
        </form>
      </Card>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        // onClick={this.updateFormValues}
        // disabled={
        //   this.state.buttonLoading ||
        //   !this.state.firstName ||
        //   !this.state.lastName ||
        //   !this.state.country
        // }
      >
        Save details
        {/* {this.state.buttonLoading && (
  <CircularProgress size={30} className={classes.progess} />
)} */}
      </Button>
    </>
  );
};
