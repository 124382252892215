import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Home } from "../Home/Home";
import { Login } from "../Auth/Login";
import { Register } from "../Auth/Register";
import { AdminHomePage } from "../Admin/AdminHomePage";

import { auth } from "../../firebase/firebase";

class Root extends React.Component<any> {
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.history.push("/admin-home-page");
      }
    });
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/admin-login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/admin-home-page" component={AdminHomePage} />
      </Switch>
    );
  }
}

// export default withRouter(Root);
export const RootWithRouter = withRouter(Root);
