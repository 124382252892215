import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { List, Drawer, Typography, Divider, ListItem } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import { BottomButtonBar } from "./BottomButtonBar";

export const drawerWidth = 360;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      // backgroundColor: theme.palette.primary.main,
      padding: 40,
      backgroundColor: theme.palette.primary.main,
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    avatarContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingBottom: "24px",
    },

    avatar: {
      height: 130,
      width: 130,
      flexShrink: 0,
      flexGrow: 0,
      border: "6px solid" + theme.palette.primary.light,
    },
    textTitle: {
      width: "100%",
      color: "#ffffff",
      fontSize: 20,
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: 1.7,
    },

    textTitle1: {
      width: "100%",
      color: "#ffffff",
      fontSize: "4em",
      fontWeight: "bold",
      fontStyle: "normal",
      lineHeight: "1.25em",
    },
    textTitle2: {
      width: "100%",
      color: "#ffffff",
      fontSize: 22,
      lineHeight: "1.7em",
      fontWeight: 500,
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
  })
);

export interface IDrawerDataProps extends Array<string> {
  [index: number]: string;
}

export interface IDrawerBarProps {
  options: IDrawerDataProps;
}

export const DrawerBar = (props: IDrawerBarProps) => {
  const classes = useStyles();

  const { options } = props;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div>
        <Typography variant="h1" className={classes.textTitle1}>
          Umesh Verma
        </Typography>
        <Typography variant="h2" className={classes.textTitle2}>
          iOS Engineer at Harman. Builder of iOS apps, React explorer, Coder,
          Avid gamers and Music lover.
        </Typography>
      </div>
      <Divider />
      <List>
        {options.map((text: any, index: number) => (
          <ListItem
            button
            key={text}
            disableGutters
            component={RouterLink}
            to={"/" + text}
          >
            <Typography variant="h6" align="left" className={classes.textTitle}>
              {text
                .replace(/([A-Z])/g, " $1")
                .trim()
                .toUpperCase()}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Divider />
      <BottomButtonBar />
    </Drawer>
  );
};
