import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CssBaseline, IconButton } from "@material-ui/core";
import {
  GitHub,
  Facebook,
  LinkedIn,
  Twitter,
  Telegram,
  Instagram,
} from "@material-ui/icons/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    social: {
      display: "flex",
      marginTop: 12,
      color: "#ffffff",
    },
  })
);

export const BottomButtonBar = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.social}>
        <IconButton color="inherit" aria-label="add to github cart">
          <GitHub />
        </IconButton>
        <IconButton color="inherit" aria-label="add to linkedin cart">
          <LinkedIn />
        </IconButton>
        <IconButton color="inherit" aria-label="add to twitter cart">
          <Twitter />
        </IconButton>
        <IconButton color="inherit" aria-label="add to facebook cart">
          <Facebook />
        </IconButton>
        <IconButton color="inherit" aria-label="add to telegram cart">
          <Telegram />
        </IconButton>
        <IconButton color="inherit" aria-label="add to instagram cart">
          <Instagram />
        </IconButton>
      </div>
    </>
  );
};
