import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDvRH-qRBrYlSFG2Hg_RGNOWa5wbUtKYpM",
  authDomain: "my-portfolio-89d44.firebaseapp.com",
  databaseURL: "https://my-portfolio-89d44.firebaseio.com",
  projectId: "my-portfolio-89d44",
  storageBucket: "my-portfolio-89d44.appspot.com",
  messagingSenderId: "347835390108",
  appId: "1:347835390108:web:6e502ae3e156ba2ad5f559",
  measurementId: "G-SES7P16BNH",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
