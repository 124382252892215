import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import image from "../../assets/images/profile.jpg";
import { List, Typography, ListItem } from "@material-ui/core";
import { AboutData } from "./model/AboutData";
import { TopBarComponent } from "./TopBarComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "50rem",
    },
    profilediv: {
      display: "flex",
      justifyContent: "center",
    },
    profileContainer: {
      width: 200,
      height: 200,
      borderRadius: 8,
    },
    listText: {
      fontSize: "1.25em",
      fontWeight: "normal",
      fontStyle: "normal",
      lineHeight: "1.7em",
    },
  })
);

export const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TopBarComponent title="About Me" />
      <div className={classes.profilediv}>
        <Avatar src={image} className={classes.profileContainer} />
      </div>
      <List>
        {AboutData.map((text: any, index: number) => (
          <ListItem key={text} disableGutters>
            <Typography variant="h6" align="left" className={classes.listText}>
              {text}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export const test1 = () => {};
