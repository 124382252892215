import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TopBarComponent } from "./TopBarComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "50rem",
    },
    textTitle2: {
      width: "100%",
      fontSize: 22,
      lineHeight: "1.7em",
      fontWeight: 500,
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle3: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: 300,
      fontStyle: "italic",
      marginTop: 12,
      marginBottom: 12,
    },
    textTitle4: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: "normal",
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
  })
);

type CompanyComponentProps = {
  name: string;
  date: string;
  details?: string;
};

const CompanyComponent = ({ name, date, details }: CompanyComponentProps) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.textTitle2}>
        {name}
      </Typography>
      <Typography variant="h3" className={classes.textTitle3}>
        {date}
      </Typography>
      <Typography variant="h3" className={classes.textTitle4}>
        {details}
      </Typography>
    </div>
  );
};

export const Experience = () => {
  const classes = useStyles();

  const name1 = "Harman India - Senior Software Engineer";
  const name2 = "Web Spiders India Pvt. Ltd. - Application Programmer";
  const name3 = "Startup - Software Engineer";
  const name4 = "Startup - Associate Software Engineer";

  const date1 = "June 2019 - Current / Bengaluru India";
  const date2 = "December 2017 - June 2019 / Kolkata India";
  const date3 = "July 2016 - December 2017 / Kolkata India";
  const date4 = "June 2015 - June 2016 / Kanpur India";

  const detail1 =
    "I am working in the iOS mobile infrastructure team as part of the Client Platform tribe. We enable Spotify mobile developers to work at unprecedented speed by creating tools, libraries and innovative solutions.";

  const detail2 =
    "I am working in the iOS mobile infrastructure team as part of the Client Platform tribe. We enable Spotify mobile developers to work at unprecedented speed by creating tools, libraries and innovative solutions.";

  return (
    <div className={classes.container}>
      <TopBarComponent title="Experience" />
      <CompanyComponent name={name1} date={date1} details={detail1} />
      <CompanyComponent name={name2} date={date2} details={detail2} />
      <CompanyComponent name={name3} date={date3} />
      <CompanyComponent name={name4} date={date4} />
    </div>
  );
};
