import { createMuiTheme } from "@material-ui/core/styles";
import { cyan as MuiColor } from "@material-ui/core/colors";

const primaryColorMain = "#2D8DA7";
const primaryColorLight = "#35a5c3";
const primaryColorDark = "#25758b";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColorMain,
      light: primaryColorLight,
      dark: primaryColorDark,
    },
    secondary: {
      light: MuiColor["A200"],
      main: MuiColor["A400"],
    },
  },
  typography: {
    fontFamily: "OpenSans",
  },
  // overrides: {
  //   typography: {

  //   }
  // }
});
