import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TopBarComponent } from "./TopBarComponent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import blog from "../../assets/blogs/blog_1.jpg";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "50rem",
    },
    textTitle1: {
      width: "100%",
      fontSize: "3em",
      fontWeight: "bold",
      fontStyle: "normal",
      lineHeight: "1.25em",
    },
    textTitle3: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: 300,
      fontStyle: "italic",
      marginTop: 12,
      marginBottom: 12,
    },
    img: {
      maxWidth: "100%",
    },
    textTitle4: {
      width: "100%",
      fontSize: 18,
      lineHeight: "1.7em",
      fontWeight: "normal",
      fontStyle: "normal",
      marginTop: 12,
      marginBottom: 12,
    },
    root: {
      width: "100%",
      marginBottom: 16,
    },
  })
);

const BlogListItemComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.textTitle1}>
        <Link href="#" color="inherit">
          Xcode's Find Navigator & Search Scopes
        </Link>
      </Typography>
      <Typography variant="h3" className={classes.textTitle3}>
        09 Feb 2020 - 3 min read
      </Typography>
      <div>
        <img className={classes.img} alt="project_1" src={blog} />
        <Typography variant="h3" className={classes.textTitle4}>
          Xcode’s built-in search and replace function, present in the find
          navigator is severely underrated and unknown to many developers. It is
          very powerful and comes with various options that may look complex at
          first, but the nice and simple UI should help in getting started with
          it. Let’s take a quick look at what the Xcode’s find navigator has to
          offer.
        </Typography>
      </div>
      <Typography variant="h3" className={classes.textTitle4}>
        <Link href="#">Read More...</Link>
      </Typography>
      <Divider />
    </div>
  );
};

export const Blog = () => {
  const classes = useStyles();

  const NextButton = () => {
    return (
      <Typography variant="h3" className={classes.textTitle4}>
        <Button variant="contained" color="primary" href="#">
          Next
        </Button>
      </Typography>
    );
  };

  const options = ["", "", "", "", "", ""];

  return (
    <div className={classes.container}>
      <TopBarComponent title="Blogs" />
      <List>
        {options.map((text: any, index: number) => (
          <ListItem>
            <BlogListItemComponent />
          </ListItem>
        ))}
      </List>
      <NextButton />
    </div>
  );
};
