import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { About } from "./About";
import { Experience } from "./Experience";
import { Education } from "./Education";
import { Skills } from "./Skills";
import { Interest } from "./Interest";
import { Blog } from "./Blog";
import { Projects } from "./Projects";

import { DrawerBar } from "../AppBar/DrawerBar";
import { DrawerData } from "../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(7),
    },
  })
);

export const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <TopAppBar /> */}
      <HashRouter>
        <DrawerBar options={DrawerData} />
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <Switch>
            <Route exact path="/" component={About} />
            <Route path="/About" component={About} />
            <Route path="/Experience" component={Experience} />
            <Route path="/Education" component={Education} />
            <Route path="/Skills" component={Skills} />
            <Route path="/Interest" component={Interest} />
            <Route path="/Blog" component={Blog} />
            <Route path="/Projects" component={Projects} />
            <Redirect to="/" />
          </Switch>
        </main>
      </HashRouter>
    </div>
  );
};
