import React from "react";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: 12,
    },
    textTitle1: {
      width: "100%",
      fontSize: "2.5em",
      fontWeight: "bold",
      fontStyle: "normal",
      lineHeight: "1.25em",
    },
  })
);

type TopBarComponentProps = {
  title: string;
};

export const TopBarComponent = ({ title }: TopBarComponentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.textTitle1}>
        {title}
      </Typography>
      <Divider />
    </div>
  );
};
