import React from "react";
import ReactDOM from "react-dom";
import "./fonts/index.css";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./Components/Styles/Styles";
import { RootWithRouter } from "./Components/Routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <RootWithRouter />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
